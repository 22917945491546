.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ms-Button-menuIcons {
  display: none;
}

.container {
  max-width: 1600px;
  margin: 0px auto;

  /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);*/
}

.appsWrapper {
  width: 100%;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
  position: relative;
}
.appsWrapper .pinnedContainer {
  border-bottom: solid 1px #ddd;
  display: flow-root;
  min-height: 50px;
}

.editIconOverlay {
  height: 128px;
  width: 128px;
  position: absolute;
  z-index: 999;
}

.grab {
  cursor: grab;
}

.tileMenuButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.dropGhost {
  background-color: #f5f5f5;
  border: solid 1px #848484;
  box-shadow: inset 0px 0px 7px 0px #333;
}

.editReactSortable {
  border: 1px solid #ddd;
  /*display: flow-root;*/
  margin: 10px 0px;
  padding: 10px;
  background-color: #fafafa;
  min-height: 120px;
  text-align: left;
}

.appsContainer {
  display: flex;
}

/*
Header
*/

.header {
  min-height: 48px;
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  /* align-content: center; */
  align-items: center;
}

.header .headerLogo {
  max-height: 48px;
}

.header .headerText {
  padding-left: 2vh;
  font-weight: bolder;
  font-size: 1.3em;
  color: white;
}

.header .cduUserProfile {
  margin-right: 0px;
  margin-left: auto;
}

.appListContainer {
  /* display:grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));*/

  display: flex;
  flex-flow: wrap;
}

.noAppsPinned {
  padding: 20px 10px;
  color: #bbb;
  font-weight: bolder;
}
/*
User Apps Component
*/

.appsMenuContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

/*
App Tile
*/

.appTile:hover {
  box-shadow: 0px 4px 8px 1px rgba(138, 135, 135, 0.74);
  background-color: white;
}

.appTile {
  display: inline-block;
  display: grid;
  position: relative;
  height: 120px;
  width: 120px;
  padding: 4px;
  margin: 2px;
  text-align: center;
  float: left;
  place-items: center;
}
.appTile a {
  text-decoration: none;
  color: #000;
  max-width: 120px;
}
.appTile a img {
  height: 70px;
  width: 70px;
  border-radius: 8px;
}
.appTile a .appIconTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.appTileWrapper {
  height: 128px;
  width: 128px;
  display: inline-block;
  /*display:grid;*/
  place-items: center;
  margin: 2px;
}

.appTileWrapper:hover {
  box-shadow: 0px 4px 8px 1px rgba(138, 135, 135, 0.74);
  background-color: white;
}

/*
Search Field
*/

.searchField {
  max-width: 400px;
  margin: auto;
}

.searchField input {
  font-weight: bolder;
}

.searchField div {
  border-radius: 4px;
}

@media only screen and (max-width: 400px) {
  .appListContainer {
    justify-content: center;
    align-content: center;
  }
}
