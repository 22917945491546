.cduPortalMessages {
}

.cduPortalMessages .container {
  max-width: 700px;
  margin: 0px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
}

.cduPortalMessages .dialog {
  min-width: 300px;
}

.prevButton {
  float: left;
}

@media only screen and (min-width: 700px) {
  .dialogContainer {
    min-width: 600px;
  }
}
