.appsContainer {
  display: flex;
  flex-direction: column;
}

.usersAppsWrapper {
  padding: 1em;
}

.usersAppsWrapper .searchField {
  margin-bottom: 0.5rem;
}
