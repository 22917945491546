.cduUserProfile {
  cursor: pointer;
}

.cduProfileCardContent {
  /*padding:20px 10px;*/
  /*flex-direction: column;*/
  display: grid;
  align-items: center;
  justify-content: center;
}

.cduProfileCardInfo {
  /*margin-bottom: 20px;*/
  padding: 1.2rem;
}

.cduProfileCardContent .cduProfileCardHeader {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.cduProfileCardContent .cduProfileCardHeader .title {
  padding-left: 10px;
}

.cduProfileCardContent .cduProfileCardHeader .signOutButton {
  border: none;
  min-height: 50px;
}

.cduProfileCardContent .cduUserPersona .ms-TooltipHost {
  color: #333;
  cursor: default;
}

.cduProfileCardContent .cduUserPersona .signOut {
  font-size: 1.2em;
  color: #ddd;
  font-weight: bolder;
  cursor: pointer;
  text-align: right;
}

.cduProfileCardContent .cduUserPersona .signOut:hover {
  color: #ccc;
  font-weight: bolder;
  cursor: pointer;
}

.cduProfileCardContent .cduUserPersona .profileLink {
  display: block;
  text-decoration: none;
  color: #0078d6;
}

.cduProfileCardContent .cduUserPersona .profileLink:hover {
  text-decoration: underline;
}
